#consulation-unite {
    margin-top: 8px;
}

#consulation-unite-header-wrapper {
    border: 4px solid rgb(255, 103, 76);
    display: flex;
}

@media screen and (max-width: 580px) {
    #consulation-unite-header-wrapper {
        display: flow-root;
        border-bottom: 0px;
    }
}

#consulation-unite-header-left {
    float: left;
    width: calc(100% - 160px);
    padding: 12px;
    padding-left: 24px;
}

@media screen and (max-width: 580px) {
    #consulation-unite-header-left {
        width: 100%;
        float: initial;
        padding-bottom: 16px;
    }
}

#consulation-unite-nom-wrapper {
    margin-bottom: 4px;
}

#consulation-unite-nom-wrapper span {
    font-family: Barlow-Black;
    font-size: 30px;
}

#consulation-unite-heures-wrapper span {
    font-family: Barlow-Black;
    font-size: 15px;
}

#consulation-unite-header-right {
    float: left;
    width: 160px;
    background-color: rgb(255, 103, 76);
    text-align: center;
}

@media screen and (max-width: 580px) {
    #consulation-unite-header-right {
        width: 100%;
        float: initial;
        padding: 16px
    }
}

#consulation-unite-header-right span {
    color: white;
    font-family: Barlow-Bold;
    display: grid;
}

#consulation-unite-a-partir-de {
    font-size: 16px;
    margin-top: 18px;
}

@media screen and (max-width: 580px) {
    #consulation-unite-a-partir-de {
        margin-top: 0px;
    }
}

#consulation-unite-montant {
    font-size: 20px;
    margin-top: 4px;
}

#consulation-unite-description-thumbnail-wrapper {
    margin-top: 48px;
    display: flex;
}

@media screen and (max-width: 1280px) {
    #consulation-unite-description-thumbnail-wrapper {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 1240px) {
    #consulation-unite-description-thumbnail-wrapper {
        flex-direction: initial;
    }
}

@media screen and (max-width: 880px) {
    #consulation-unite-description-thumbnail-wrapper {
        flex-direction: column-reverse;
    }
}

#consulation-unite-description-wrapper {
    float: left;
    width: calc(100% - 480px);
    height: 100%
}

@media screen and (max-width: 1280px) {
    #consulation-unite-description-wrapper {
        width: 100%;
        height: 296px;
    }
}

@media screen and (max-width: 1240px) {
    #consulation-unite-description-wrapper {
        width: calc(100% - 480px);
    }
}

@media screen and (max-width: 880px) {
    #consulation-unite-description-wrapper {
        width: 100%;
    }
}

#consulation-unite-description-label {
    font-family: Barlow-Black;
    font-size: 24px;
    display: block;
    margin-bottom: 8px;
}

#consulation-unite-description-text {
    font-size: 18px;
    font-family: Barlow-SemiBold;
    padding-right: 48px;
    display: block;
    margin-bottom: 16px;
}

#consulation-unite-description-icons {
    margin-bottom: 24px;
    display: flow-root;
}

#consulation-unite-thumbnail-wrapper {
    float: left;
    width: 480px;
    height: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
    max-width: 100%;
}

#consulation-unite #consulation-unite-thumbnail-wrapper {
    float: left;
    width: 100%;
    height: 450px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1280px) {
    #consulation-unite-thumbnail-wrapper {
        margin-bottom: 32px;
        height: 296px;
    }
}

@media screen and (max-width: 1240px) {
    #consulation-unite-thumbnail-wrapper {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 880px) {
    #consulation-unite-thumbnail-wrapper {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 400px) {
    #consulation-unite-thumbnail-wrapper {
        height: 240px;
        width: 360px;
    }
}

#consulation-unite #consulation-unite-thumbnail-wrapper img {
    object-fit: cover;
    transition: opacity 0.8s ease-in-out;;
    position: absolute;
    width: unset;
    height: unset;
}

#consulation-unite-thumbnail-wrapper img {
    object-fit: cover;
    transition: opacity 0.8s ease-in-out;;
    position: absolute;
    width: 100%;
    height: 100%;
}

.consulation-unite-thumbnail-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.consulation-unite-thumbnail-arrow.has-another-thumbnail:hover {
    cursor: pointer;
}

#consulation-unite-thumbnail-arrow-left {
    left: 24px;
}

#consulation-unite-thumbnail-arrow-right {
    right: 36px;
}

.consulation-unite-thumbnail-arrow-part {
    border: 2px solid black;
    height: 24px;
    width: 16px;
    background-color: white;
    position: absolute;
}

.consulation-unite-thumbnail-arrow-part.has-another-thumbnail {
    background-color: rgb(255, 103, 76);
}

#consulation-unite-thumbnail-arrow-left-top {
    transform: skew(-36deg, 0deg);
    border-bottom: 0px;
    bottom: 0px;
}

#consulation-unite-thumbnail-arrow-left-bottom {
    transform: skew(36deg, 0deg);
    border-top: 0px;
    top: 0px;
}

#consulation-unite-thumbnail-arrow-right-top {
    transform: skew(36deg, 0deg);
    border-bottom: 0px;
    bottom: 0px;
}

#consulation-unite-thumbnail-arrow-right-bottom {
    transform: skew(-36deg, 0deg);
    border-top: 0px;
    top: 0px;
}

.consulation-unite-liste-wrapper span {
    font-family: Barlow-Black;
    font-size: 24px;
}

.consulation-unite-liste-wrapper li {
    font-family: Barlow-SemiBold;
    font-size: 18px;
}