@media print {
    body {
        display: none !important
    }
}
.main-content-wrapper {
    box-shadow: 0px 0px 5px #1a1a1a;
    padding: 200px;
    background: #FFF;
}
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
cache-page {
    box-shadow: 0px 0px 5px #1a1a1a;
    padding: 20px;
    background: #FFF;
}

html {
    font-family: arial;
}

body {
    margin: 0;
    padding: 0;
    /*background: #F9F9F9;*/
}

a:focus, a:hover {
    /* color: #074673; */
    text-decoration: underline;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.min-width-7-digits-text-box {
    min-width: 80px;
}

.min-width-table-column {
    width: 1px;
}

.min-width-fit-content {
    min-width: fit-content;
}

.radio-group-label {
    margin-bottom: 8px;
}
input, html .rc-time-picker-input {
    font-size: 1.2em !important;
}
#google-map-div {
    width: 100%;
    height: 400px;
    background-color: grey;
}

    #google-map-div img {
        max-width: none;
    }

    #google-map-div canvas {
        max-width: none;
    }
