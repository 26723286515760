
.modifier {
    /*font-size: 18px;*/
    margin-left: 1em;
}

#droit-acces-page2 {
    width: 100%;
    padding: 10px;
    max-width: calc(100% - (100% - 1550px) / 2 - 16px);
    /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
    /* padding-left: 52px; */
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#droit-acces-page2-main-content {
    margin-top: 16px;
    /* display: flow-root; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-droit-acces-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.move-width {
    width: 60% !important;
    display: flex;
}

.point-space {
    font-family: Barlow-Bold;
    font-size: 26px;
}

#droit-acces-page2 .droit-acces-div {
    /* padding: 8px; */
    padding: 0px 8px 8px 8px;
    justify-content: center;
    padding-left: 0px;
    width: 100%;
}

#droit-acces-page2 .droit-acces-span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 100%;
}

.stepper-wrapper,
.stepper-wrapper-small-screen {
    /*margin-bottom: 32px;*/
}

.stepper-wrapper .step {
    display: flex;
    flex-direction: column;
}

.stepper-wrapper {
    width: 100%;
    padding: 0% 11%;
}

.droit-acces-page-separator {
    border-bottom: 2px solid black;
    width: calc(100% - 524px + 480px + 40px);
    clear: both;
    margin-top: 16px;
    display: inline-block;
}

#droit-acces-page2 .droit-acces-section {
    display: flex !important;
    width: 100% !important;
    padding: 0% 11% !important;
    flex-direction: column !important;
    align-items: center !important;
}

.label-sommaire {
    display: grid;
    grid-template-rows: auto 1fr;
    justify-content: space-between;
    align-items: center;
}

#droit-acces-page2 .droit-acces-label-wrapper {
    /* width: 320px; */
    float: left;
    width: 30%;
    display: flex;
    align-items: center;
}

.droit-acces-label-wrapper-customize {
    width: 38% !important;
}

.input-container-customize {
    width: 62% !important;
}

#droit-acces-page2 .input-container {
    /* width: auto; */
    width: 40%;
    display: inline-block;
    /* width: calc(100% - 342px - 40px); */
    /* float: left; */
}

#droit-acces-page2 .droit-acces-input {
    width: 100%;
}

.droit-acces-input-error {
    border-color: red;
}

#droit-acces-page2 .droit-acces-img {
    height: 40px;
    margin-left: 16px;
    margin-top: 12px;
}

.droit-acces-page2.btn-span-wrapper span {
    font-size: 2em;
}

.droit-acces-page.btn-span-wrapper span {
    font-size: 2em;
}

.droit-acces-page2 input[type="checkbox"]:checked:after {
    font-size: 28px; /* Ne pas utiliser em ici. C'est utilisé non pas comme texte mais pour X dans le checkbox. Changer la taille de texte ici nécessite de changer la taille ou le padding du checkbox pour recentrer le X. */
}

#droit-acces-page2 .droit-acces-img:hover {
    cursor: pointer;
}

#droit-acces-page2 .message {
    font-family: Barlow-SemiBold;
    width: 100%;
}

.droit-acces-label-wrapper-title {
    width: 100% !important;
    margin-top: 16px !important;;
    margin-bottom: 16px !important;;
}

#droit-acces-page2 .droit-acces-label-wrapper-title .droit-acces-span {
    font-family: Barlow-Black;
    display: flow-root;
}

.droit-acces-div-previous,
.droit-acces-div-next {
    padding: 8px;
    min-width: 10rem !important;
    width: auto !important;
}

.droit-acces-div-previous {
    text-align: center !important;;
    float: left;
}

.droit-acces-div-next {
    text-align: center !important;
    float: right;
}

.step-buttons {
    /* margin: auto;
          width: 70%; */
    width: 100%;
    padding: 0% 11%;
}

.droit-acces-page2 span.label,
.droit-acces-page2 div.label {
    font-size: 1.95em;
}

#droit-acces-next-wrapper {
    background-color: rgb(255, 103, 76, 1);
    /* clear: both; */
    margin-top: 32px;
    color: white;
}

#droit-acces-next-wrapper:hover {
    cursor: pointer;
}

#droit-acces-next-wrapper .droit-acces-span {
    color: white;
}

#droit-acces-page2 .droit-acces-span-primary {
    color: var(--primary-color);
    font-size: 26px;
    width: 480px;
}

select option {
    font-family: Barlow-Regular !important;
}

.div-accompagneur {
    clear: both;
    margin-bottom: 16px;
    display: flex;
}

#droit-acces-page2 .droit-acces-span.btn {
    font-family: Barlow-Black;
}

.droit-acces-sommaire-span-left {
    float: left;
    font-family: Barlow-Regular;
    margin-right: 32px;
    word-break: break-word;
}

.droit-acces-sommaire-span-right {
    float: right;
    font-family: Barlow-Regular;
    width: initial;
    justify-self: end;
}

#droit-acces-page2 .droit-acces-span.droit-acces-sommaire-span-right {
    width: initial;
}

/* preview file */
/* onglets */

.tabs {
    margin-bottom: 10px;
}

/* pas de documenet disponible */

.noFile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    height: 100%;
}

.tabs button:hover {
    background-color: #ddd;
}

.tabs button.active-tab {
    color: #ffffff;
    background-color: rgb(255, 103, 76, 1);
}

.tabs button {
    color: rgb(190, 185, 185);
    border: none;
    padding: 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
}

.tab-button-view-pdf {
    margin: 6px 0px 3px !important;
}

.tab-button {
    margin: 0px 10px 0px !important;
}

.section-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.section-content.responsable .input-field-container .input-field-label {
}

.section-content.responsable .input-field-container .input-field-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30rem;

    img {
        height: 40px;
    }

    select {
        width: 100%;
    }

    input {
        width: 100%;
    }
}

.input-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

@media screen and (max-width: 1200px) {
    #droit-acces-page2 {
        .step-title {
            display: none;
        }

        .input-container-customize, .move-width, .move-width, .step-buttons, .input-field-input, .section-content {
            width: 100% !important;
        }

        .input-field-container {
            gap: 0;
        }

        .step-buttons, .droit-acces-section {
            padding: 0 !important;
            margin: 0 !important;
        }

        input[type=text] {
            width: 100% !important;
        }

        .droit-acces-div {
            flex: 1;
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .rdtOpen .rdtPicker {
        right: 0;
    }
}