.item-popup-background {
    max-width: 80vw;
    max-height: 80vh;
    min-height: 256px;
    min-width: 275px;
}

.item-popup-font {
    font-size: 20px;
}

.item.popup-name {
    padding-left: 210px;
}

.item-popup-details {
    background-color: #ebebeb;
    padding: 20px;
    display: block;
}

.item-popup-desc {
    font-size: 20px;
    margin: 10px;
}

.table-header-produit {
    padding-left: 30px;
    text-align: left;
    width: calc(100% - 100px - 80px);
}

.table-header th {
    font-size: 18px;
}

.table-header-prix {
    text-align: left;
    width: 100px;
}

.table-header-quantite {
    width: 80px;
}

.table-row-prix {
    padding-left: 20px;
    text-align: left;
}

.table-row-prix-error {
    color: #d8000c;
    text-align: left;
    width: 40px;
}

.table-row-desc {
    font-size: 16px;
    padding-left: 35px;
    text-align: left;
}

.table td input[type="number"] {
    border: none;
    background: #d3d3d3;
    font-size: 20px;
    margin: 5px 0 9px 0;
    padding: 10px;
    width: 50px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: button;
    opacity: 1;
}

.table td input[type="checkbox"] {
    border: none;
    background: #d3d3d3;
    font-size: 20px;
}

#popup-produit-optionnel .table td input[type="checkbox"] {
    /*width: 100px*/
    left: -20px;
}

.table td input[type="text"]:focus {
    background-color: #ddd;
    outline: none;
}

.member-popup-btn {
    margin: auto;
    color: white;
    padding: 14px 20px;
    border: none;
    cursor: pointer;
    width: 90%;
    margin-bottom: 1px;
    font-size: 20px;
    opacity: 0.85;
}

.member-popup-btn:hover {
    opacity: 1;
}

.member-confirm-btn {
    background-color: #4caf50;
}

.non-member-confirm-btn {
    background-color: #dcd758;
    color: Black;
}

@supports not (-moz-appearance:none) {
    input[type=checkbox] {
        position: relative;
        cursor: pointer;
        width: 0;
        height: 0;
        margin: 0;
        margin-bottom: 50px;
    }
}
