.popup-tranche-age-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

.popup-tranche-age-content {
    max-width: 30em;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3em 1fr 3em;
    width: 100%;
    background-color: white;
    border: 2px solid var(--primary-color);
}

.tranche-age-header {
    grid-row: 1/1;
    background-color: var(--primary-color);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
}

.tranche-age {
    display: grid;
    grid-row: 2/2;
    padding: 0 10px;
    grid-template-columns: 2fr;
}

.tranche-age-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 0;
}

.tranche-age-button {
    grid-row: 3/3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
}

.tranche-age-button button {
    padding: 5px;
    width: 100px;
    margin: 5px;
}

.tranche-age .SpinInput {
    width: 5em;
    grid-column: 2/2;
}

.tranche-age .SpinInput input {
    font-size: 1em;
}


/* option forfait */
.option-fofait-content {
    max-width: 85em !important;

}

.option-forfait-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    overflow-y: scroll;
}

.option-forfait-body {
    border-top: 0 solid rgba(253, 102, 76, 0.6);
}

.option-forfait {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    /*border: 1px solid rgb(253, 102, 76, 0.6);*/
}

.option-forfait-body > div:nth-child(odd) {
    /* background-color: rgba(253, 102, 75, 0.1);*/

}

.option-content-body-left {
    width: 18%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.description {
    width: 80%;
    text-align: left;
    margin-right: 10px;
}

.price {
    font-weight: bold;
    font-size: 1.2rem;
}

.selected {
    /*margin: 8px 0px;*/
    /*transform: translateY(3px);*/
    box-shadow: 0 0 2px rgba(253, 102, 75, 1);
    background-color: rgba(253, 102, 75, 0.16) !important; /* Couleur de fond lorsqu'une option est sélectionnée */
    border-left: 0.2rem solid rgba(253, 102, 75);
}

.hovered {
    /*   margin: 6px 0;*/
    /*transform: translateY(3px);*/
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    /* background-color: #f8f8f8; Couleur de fond au survol */
}
  
  