header {
    background-color: black;
    width: 100%;
    min-height: 17rem;
    align-content: center;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

#header-left {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

#header-left img {
    max-height: 6rem;
}

#header-right {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    font-size: 26px;
    align-content: center;
    gap:8rem;
}

#header-right div {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-weight: bold;
    margin: auto;
}

#header-right span:hover {
    cursor: pointer;
}

/*
.add-margin-after-login {
    width: 711px !important;
}*/
