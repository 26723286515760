.panier-icon {
    position: fixed;
    /* position: absolute; */
    font-size: 20px;
    z-index: 999;
    top: 2%;
    right: 2%;
}

.glow-on-hover {
    /* width: 220px; */
    /* height: 50px; */
    border: none;
    outline: none;
    color: #fff;
    background: black;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
    background-color: rgb(255, 103, 76, 1);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: black
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    left: 0;
    top: 0;
    border-radius: 10px;
}

#cartCount {
    font-size: 13px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px 0 5px;
    vertical-align: top;
    position: absolute;
    margin-top: -5px;
    margin-left: -6px;
    border-radius: 60%;
    /* height: 16px;
    width: 12px; */
}

/* @media screen and (max-width: 630px) { */
@media screen and (max-width: 780px) {
    .panier-icon {
       
    }
}