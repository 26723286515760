#achat-forfait-page-container, #billetterie-page-container, #tirage-page-container,
#calendrier-page-container, #kiosque-page-container {
    margin-top: 10px;
    margin-bottom: 12px;
    /* text-align: center; */
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.sommaire-right-panel {
    display: inline-block;
    /* border: 3px solid rgb(255, 103, 76, 1); */
    position: sticky;
    top: 5rem;
    margin-top: 5px;
    margin-right: 30px;
    margin-left: 15px;
    max-width: 350px;
}

.max-width {
    width: 400px;
    max-width: 400px;
}