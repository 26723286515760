.modifier {
    font-size: 18px;
    margin-left: 1em;
}

#droit-acces-page {
    width: 100%;
    padding: 10px;
    max-width: calc(100% - (100% - 1550px) / 2 - 16px);
    /* 480%: la section des règlements; (100% - 1550px) / 2: la largeur de la bande du menu principale excluant la marge de gauche; 16px: la marge à gauche des icônes de calendrier; */
    /* padding-left: 52px; */
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1550px) {
    #droit-acces-page {
        padding-left: 32px;
        max-width: 100%;
    }
}

@media screen and (max-width: 940px) {
    #droit-acces-page {
        max-width: initial;
    }
}

@media screen and (max-width: 720px) {
    #droit-acces-page {
        padding-left: 32px;
        margin-left: initial;
        float: left;
    }
}

#droit-acces-page-main-content {
    margin-top: 16px;
    /* display: flow-root; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-droit-acces-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.move-width {
    width: 60% !important;
    display: flex;
}

.point-space {
    font-family: Barlow-Bold;
    font-size: 26px;

}

#droit-acces-page .droit-acces-div {
    /* padding: 8px; */
    padding: 0px 8px 8px 8px;
    justify-content: center;
    padding-left: 0px;
    width: 100%;
}

@media screen and (max-width: 820px) {
    #droit-acces-page-main-content {
        width: 100%;
    }
}

@media screen and (max-width: 780px) {
    #droit-acces-page .droit-acces-div {
        width: 100%;
    }
}

#droit-acces-page .droit-acces-span {
    font-family: Barlow-Bold;
    font-size: 26px;
    width: 480px;
}

@media screen and (max-width: 780px) {
    #droit-acces-page .droit-acces-span {
        width: 100%;
    }
}

.stepper-wrapper, .stepper-wrapper-small-screen {
    /*margin-bottom: 32px;*/
}

.stepper-wrapper {
    width: 100%;
    padding: 0% 11%;
}

@media screen and (max-width: 960px) {
    #droit-acces-page .stepper-wrapper {
        display: none;
    }
}

@media screen and (min-width: 960px) {
    #droit-acces-page .stepper-wrapper-small-screen {
        display: none;
    }
}

.droit-acces-page-separator {
    border-bottom: 2px solid black;
    width: calc(100% - 524px + 480px + 40px);
    clear: both;
    margin-top: 16px;
    display: inline-block;
}

.droit-acces-section {
    display: flow-root;
    display: inline-block;
    position: relative;
    /* margin-left: 23%; */
    /* max-width: 68%; */
    width: 100%;
    padding: 0% 11%;
}

.label-sommaire {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1140px) {
    .droit-acces-section {
        margin-left: 16%;
        /* max-width: 50%; */
    }
}

@media screen and (max-width: 800px) {
    .droit-acces-section {
        margin-left: 12%;
    }
}

@media screen and (max-width: 610px) {
    .droit-acces-section {
        margin-left: 8%;
        max-width: 100%;
        width: auto;
    }
}

#droit-acces-page .droit-acces-label-wrapper {
    /* width: 320px; */
    float: left;
    width: 30%;
    display: flex;
    align-items: center;
}

.droit-acces-label-wrapper-customize {
    width: 38% !important;
}

.input-container-customize {
    width: 62% !important;
}

@media screen and (max-width: 780px) {
    #droit-acces-page .droit-acces-label-wrapper {
        width: 100%;
    }
}

#droit-acces-page .input-container {
    /* width: auto; */
    width: 40%;
    display: inline-block;
    /* width: calc(100% - 342px - 40px); */
    /* float: left; */
}

@media screen and (max-width: 780px) {
    #droit-acces-page .input-container {
        /* width: calc(100% - 64px); */
    }
}

#droit-acces-page .droit-acces-input {
    width: 100%;
}

.droit-acces-input-error {
    border-color: red;
}

#droit-acces-page .droit-acces-img {
    height: 40px;
    margin-left: 16px;
    margin-top: 12px;
}

.droit-acces-page .btn-span-wrapper span {
    font-size: 2em;
}

.droit-acces-page.btn-span-wrapper span {
    font-size: 2em;
}

.droit-acces-page input[type=checkbox]:checked:after {
    font-size: 28px; /* Ne pas utiliser em ici. C'est utilisé non pas comme texte mais pour X dans le checkbox. Changer la taille de texte ici nécessite de changer la taille ou le padding du checkbox pour recentrer le X. */
}

#droit-acces-page .droit-acces-img:hover {
    cursor: pointer;
}

#droit-acces-page .message {
    font-family: Barlow-SemiBold;
    width: 100%;
}

.droit-acces-label-wrapper-title {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

#droit-acces-page .droit-acces-label-wrapper-title .droit-acces-span {
    font-family: Barlow-Black;
    display: flow-root;
}

.droit-acces-div-previous, .droit-acces-div-next {
    padding: 8px;
    width: 20%;
}

.droit-acces-div-previous {
    text-align: left;
    float: left;
}

.droit-acces-div-next {
    text-align: right;
    float: right;
}

.step-buttons {
    /* margin: auto;
    width: 70%; */
    width: 100%;
    padding: 0% 11%;
}

.droit-acces-page span.label, .droit-acces-page div.label {
    font-size: 1.95em;
}

@media screen and (max-width: 780px) {
    .step-buttons {
        width: initial;
    }
}

#droit-acces-next-wrapper {
    background-color: rgb(255, 103, 76, 1);
    /* clear: both; */
    margin-top: 32px;
    color: white;
}

@media screen and (max-width: 1210px) {
    #droit-acces-next-wrapper {
        text-align: center;
    }
}

#droit-acces-next-wrapper:hover {
    cursor: pointer;
}

#droit-acces-next-wrapper .droit-acces-span {
    color: white;
}

#droit-acces-page .droit-acces-span-primary {
    color: var(--primary-color);
    font-size: 26px;
    width: 480px;
}

select option {
    font-family: Barlow-Regular !important;
}

.div-accompagneur {
    clear: both;
    margin-bottom: 16px;
    display: flex;
}

/*.div-accompagneur:hover {
  background-color: rgb(255, 185, 172);
}*/

#droit-acces-page .droit-acces-span.btn {
    font-family: Barlow-Black;
}

@media screen and (max-width: 700px) {
    .droit-acces-div-previous, .droit-acces-div-next {
        width: 40%;
    }

    .droit-acces-div-previous span, .droit-acces-div-next span {
        font-size: 15px !important;
        padding: 4px !important;
        width: auto !important;
        white-space: nowrap;
    }
}

@media screen and (max-width: 768px) {
    .droit-acces-vehicules-tr {
        padding: 8px;
        box-shadow: 0px 0px 3px 2px var(--medium-gray);
        /*background-color: var(--light-gray);*/
        margin-bottom: 16px;
        display: inline-block;
    }

    .droit-acces-vehicules-td-select {
        display: block;
        float: left;
        width: 100%;
    }

    .droit-acces-vehicules-td-description {
        display: block;
        float: right;
        float: left;
        margin-left: -16px;
        margin-top: 16px;
    }

    .droit-acces-vehicules-td-fqcq {
        display: block;
        width: 100%;
        float: left;
        margin-left: -16px;
        margin-top: 16px;
    }

    .droit-acces-vehicules-td-edit {
        display: block;
        float: right;
        float: left;
        margin-left: -32px;
    }

    .droit-acces-vehicules-td-delete {
        display: block;
        float: right;
    }
}

@media screen and (min-width: 544px) {
    .droit-acces-activite-popup-content {
        min-width: 544px;
    }
}

@media screen and (max-width: 1024px) {
    .droit-acces-choix-accompagnateur-tr {
        padding: 8px;
        box-shadow: 0px 0px 3px 2px var(--medium-gray);
        margin-bottom: 32px;
        display: inline-block;
    }

    .droit-acces-choix-accompagnateur-td-nom {
        display: block;
        float: left;
        width: 100%;
        word-break: break-word;
    }

    .droit-acces-choix-accompagnateur-td-vehicule {
        display: block;
        float: left;
        width: 100%;
        margin-left: -16px;
        margin-top: 16px;
    }

    .droit-acces-choix-accompagnateur-td-fqcq {
        display: block;
        float: left;
        width: 100%;
        margin-left: -32px;
        margin-top: 16px;
    }
}

.droit-acces-sommaire-span-left {
    float: left;
    font-family: Barlow-Regular;
    margin-right: 32px;
    word-break: break-word;
}

@media screen and (min-width: 1280px) {
    #droit-acces-page .droit-acces-span.droit-acces-sommaire-span-left {
        width: 800px;
    }
}

.droit-acces-sommaire-span-right {
    float: right;
    font-family: Barlow-Regular;
    width: initial;
}

#droit-acces-page .droit-acces-span.droit-acces-sommaire-span-right {
    width: initial;
}

@media screen and (max-width: 780px) {
    #droit-acces-page .droit-acces-span.droit-acces-sommaire-span-right {
        width: initial;
    }
}

/* preview file */
/* onglets */
.tabs {
    margin-bottom: 10px;
}

/* pas de documenet disponible */
.noFile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    height: 100%;
}

.tabs button:hover {
    background-color: #ddd;
}

.tabs button.active-tab {
    color: #ffffff;
    background-color: rgb(255, 103, 76, 1);
}

.tabs button {
    color: rgb(190, 185, 185);
    border: none;
    padding: 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
}

.tab-button-view-pdf {
    margin: 6px 0px 3px !important;
}

.tab-button {
    margin: 0px 10px 0px !important;
}