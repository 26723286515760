#main-menu {
    background-color: #13322B;
    padding: 32px 0;
    text-align: center;
}

@media screen and (max-width: 1280px) {
    #main-menu {
        padding-top: 0px;
        height: initial;
    }
}

#main-menu-center {
    padding: 0 100px;
    display: flex;
    margin: auto;
    font-size: 24px;
    gap: 1rem;
}

@media screen and (max-width: 1280px) {
    #main-menu-center {
        display: flow-root;
    }
}

@media screen and (max-width: 740px) {
    #main-menu-center {
        display: grid;
    }
}

.main-menu-item-wrapper {
    display: inline-block;
    margin: auto;
    position: relative;
    padding-right: 5px;
}

@media screen and (max-width: 1280px) {
    .main-menu-item-wrapper {
        padding: 8px;
        width: 33%;
    }
}

@media screen and (max-width: 1080px) {
    .main-menu-item-wrapper {
        width: 50%;
    }
}

@media screen and (max-width: 740px) {
    .main-menu-item-wrapper {
        width: initial;
    }
}

#main-menu-center span {
    font-family: Barlow-Bold;
}

#main-menu-center span:hover {
    cursor: pointer;
}

.main-menu-sub-menu {
    background-color: rgb(208, 222, 187, 1);
    position: absolute;
    top: 64px;
    left: -42px;
    padding: 8px 24px 8px 24px;
    z-index: 1;
}

.main-menu-sub-menu span {
    font-family: Barlow-Bold;
    display: block;
    padding: 16px;
    font-size: 24px;
}

/*.main-menu-sub-menu span:hover {
    color: rgb(255, 103, 76);
}*/

.main-menu-sub-menu-separator {
    border-bottom: 2px solid black;
    display: block;
}