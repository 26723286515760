.SpinInput {
  position: relative;
  background-color: white;
  width: 100%;  
  font-size: 1em;
}

.SpinInput .Arrow {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex!important;
  flex-direction: column;
  border-left: 1px solid rgba(119, 119, 119, 0.288);
}

.SpinInput .Arrow .Up,
.SpinInput .Arrow .Down {
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  padding: 0 2px;
  margin: 0px !important;
  background-color: rgba(0, 0, 0, 0.05);
}

.SpinInput .Arrow .Up:hover,
.SpinInput .Arrow .Down:hover {
  cursor: default;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.2);
}

/*Masquer les spinners pour les navigateurs Webkit (chromme, safari, etc.)*/
.SpinInput input[type="number"]::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/*Masquer les spinners pour les autres navigateurs differents de Webkit*/
.SpinInput input[type="number"] {
  appearance:textfield;
}

.SpinInput input {
  width: 100%;
  text-align: right;
  padding-right: 25px;
}
