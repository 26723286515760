#kiosque-page-container .achat-forfait-card-container {
    border: none;
    width: auto;
}

.billeterie-div-filter {
    margin-left: 10%;
    border: 0;
}

.button-achat-billet {
    margin-left: 10px;
    background-color: rgb(255, 248, 247);
    color: rgb(255, 103, 76, 1);
    font-family: Barlow-Black;
    padding: 4px 12px 4px 12px;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 24px;
    cursor: pointer;
}

#kiosque-page-container .billetterie-header {
    color: rgb(255, 103, 76, 1);
    font-family: Barlow-Bold;
    text-transform: uppercase;
    font-size: 2.4em;
    margin: 5px;
    margin-left: 10px;
}

#kiosque-page-container .achat-forfait-card-container .button-achat-billet:hover {
    background-color: rgb(247, 234, 231);
}

#kiosque-page-container .achat-forfait-card-container table {
    border-collapse: collapse;
}

#kiosque-page-container .achat-forfait-card-container tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    /* border-bottom: 3px solid rgb(255, 103, 76, 1); */
}

#kiosque-page-container .achat-forfait-card-container table td.col1 {
    /* width: 110px; */
    padding-left: 10px;
    padding-right: 0;
}

#kiosque-page-container .achat-forfait-card-container table td.col3 {
    /* width: 110px; */
    padding-top: 10px;
    font-size: large;
    padding-left: 20px;
    padding-right: 0;
    height: 85px;
    vertical-align: middle;
}

#kiosque-page-container .achat-forfait-card-container table.donnees td {
    margin: 0;
    padding: 5px 8px;
    border-bottom: 1px solid #CCC;
    vertical-align: top;
}

#kiosque-page-container .achat-forfait-card-container td {
    text-align: left;
    display: table-cell;
    vertical-align: inherit;
}

#kiosque-page-container .achat-forfait-card-container a {
    font-weight: normal;
    text-decoration: underline;
    color: #5A0000;
    font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

#kiosque-page-container .achat-forfait-card-container a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

#kiosque-page-container .achat-forfait-card-container element.style {
    background-image: url(https://www.ticketacces.net/organisations/cinemaamos/evenements/grandes/sos-fantomes.jpg);
}

#kiosque-page-container .achat-forfait-card-container img.image-evenement {
    margin-top: 30px;
    margin-bottom: 10px;
    /* width: 250px; */
    max-height: 185px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    position: relative;
    vertical-align: middle;
    background-color: #eee;
}

#kiosque-page-container .achat-forfait-card-container table td {
    padding-top: 20px;
    padding-bottom: 20px;
}

#kiosque-page-container .achat-forfait-card-container table.donnees td {
    margin: 0;
    padding: 5px 8px;
    border-bottom: 1px solid #CCC;
    vertical-align: top;
}


#kiosque-page-container .achat-forfait-card-container table td {
    border: 0;
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    vertical-align: top;
}

#kiosque-page-container .achat-forfait-card-container a {
    font-weight: normal;
    text-decoration: underline;
    color: #5A0000;
    font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

#kiosque-page-container .achat-forfait-card-container a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

#kiosque-page-container .achat-forfait-card-container p.date {
    color: #222;
    font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
    font-size: 1.125rem;
    margin-bottom: 5px;
}

#kiosque-page-container .achat-forfait-card-container table td p {
    text-align: left;
}

#kiosque-page-container .achat-forfait-card-container p {
    text-align: left;
    margin: 0 0 1em 0;
    padding: 0;
    line-height: 1.4em;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

/* #kiosque-page-container .achat-forfait-card-container div.clear, hr.clear {
    clear: both;
    height: 0;
    line-height: 0;
    border: 0;
    font-size: 0;
    margin: 0;
    padding: 0;
} */

#kiosque-page-container .achat-forfait-card-container div {
    display: block;
    /* display: inline-block; */
}

@media print, screen and (min-width: 64em) {
    #kiosque-page-container .achat-forfait-card-container .large-text-justify {
        text-align: justify;
    }
}

#kiosque-page-container .achat-forfait-card-container .text-left {
    text-align: left;
}

#kiosque-page-container .achat-forfait-card-container div.bulles {
    display: flex;
    flex-wrap: wrap;
}

#kiosque-page-container .achat-forfait-card-container div {
    display: block;
}

#kiosque-page-container .achat-forfait-card-container div.bulle-indicateur {
    float: left;
    margin: 0 0 5px 0;
    z-index: 10;
}


#kiosque-page-container .achat-forfait-card-container div.bulle {
    float: left;
    margin: 5px 10px 10px -2px;
    padding: 0 0 0 10px;
    color: #555;
    min-width: 65px;
    border-left: 1px dotted #CCC;
}

#kiosque-page-container .achat-forfait-card-container strong {
    font-weight: normal;
    font-family: 'FiraSans-SemiBold', Verdana, Geneva, sans-serif;
}

#kiosque-page-container .kiosque-container {
    margin-bottom: 20px;
    border: 4px solid rgb(255, 103, 76, 1);
}

#kiosque-page-container .achat-forfait-card-container table td.col2 {
    padding-left: 20px;
    padding-bottom: 0px;
}

@media print, screen and (max-width: 1020px) {
    #kiosque-page-container .achat-forfait-card-container .col1 {
        display: inline-block;
    }

    #kiosque-page-container .achat-forfait-card-container .col2 {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }

    #kiosque-page-container .achat-forfait-card-container .col1 {
        padding: 2px !important;
    }

    #kiosque-page-container .achat-forfait-card-container img.image-evenement {
        margin: 2px;
        width: 265px;
        height: 210px;
    }

    .billeterie-div-filter {
        margin-left: 4%;
    }
}

#kiosque-page-container .box {
    border: 1px solid #000;
    padding: 10px;
    margin: 8px 8px 4px 0px
}

#kiosque-page-container .first-row {
    margin-bottom: 10px;
    text-align: center;
}

#kiosque-page-container .second-row {
    display: flex;
}

#kiosque-page-container .number-input {
    flex: 1;
    height: 50px;
    width: 50px;
    -webkit-appearance: none;
    text-align: center;
    margin: 0;
    cursor: default;
}

/* Firefox */
#kiosque-page-container input[type=number] {
    -moz-appearance: textfield;
}

#kiosque-page-container .plus,
#kiosque-page-container .minus {
    padding: 5px 10px;
    height: 50px;
    width: 50px;
    font-size: 30px;
    background-color: #FFFFFF;
    border: 2px solid rgb(255, 103, 76, 1);
    cursor: pointer;
}