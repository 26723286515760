.card-container {
    margin: auto;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 32px;
}

@media screen and (max-width: 1550px) {
    .card-container {
        margin-bottom: 24px;
        margin-top: 0px;
    }
}

@media screen and (max-width: 1420px) {
    .card-container {
        margin-bottom: 16px;
    }
}

.card-sub-container {
    /*display: inline-flex;
    overflow: auto;*/
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
}