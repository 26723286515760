.calendar-wrapper {
  position: absolute;
  width: 254px;
  border: 2px solid rgb(255, 103, 76);
  background-color: white;
  z-index: 1000;
  padding: 0 10px;
  padding-bottom: 2px;
}

.calendar {
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr 6fr;
  align-items: center;
}

.calendar * {
  font-family: Barlow-Black;
  font-size: 1em;
}

.calendar-dates-wrapper {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-row-gap: 6px;
}

.calendar-month-section-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(153, 153, 153, 1);
}

span.grayed-day {
  color: rgba(0, 0, 0, 0.5);
}

.calendar-month-section-wrapper span {
  font-size: 1.2em;
}

.calendar-previous-month-btn {
  transform: rotate(90deg);
}

.calendar-next-month-btn {
  transform: rotate(-90deg);
}

.calendar-previous-month-btn:hover,
.calendar-next-month-btn:hover {
  cursor: pointer;
}

.calendar-cell-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.calendar-cell.clickable:hover {
  filter: opacity(60%);
}
.calendar-cell.clickable:hover div:not(.cell-am-disponible, .cell-pm-disponible, .cell-am-indisponible, .cell-pm-indisponible, .cell-date-selected, .cell-date-selected-debut-fin) {
  background-color: rgb(164, 200, 248);
}

.calendar-cell {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
}

.cell-am-disponible,
.cell-pm-disponible,
.cell-am-indisponible,
.cell-pm-indisponible,
.cell-non-applicable {
  position: relative;
  overflow: hidden;
}

.cell-non-applicable {
  color: white;
}

.cell-am-disponible::before,
.cell-pm-disponible::before,
.cell-am-indisponible::before,
.cell-pm-indisponible::before,
.cell-am-disponible::after,
.cell-pm-disponible::after,
.cell-am-indisponible::after,
.cell-pm-indisponible::after,
.cell-non-applicable::before,
.cell-non-applicable::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 0;
}

.calendar-cell span {
  position: relative;
  z-index: 1;
}
.cell-am-disponible::before {
  background-color: rgb(149, 220, 101);
  height: 100%;
  width: 100%;
  transform: skew(-45deg, 45deg);
  transform-origin: 100% 0;
}
.cell-pm-disponible::after {
  background-color: rgb(149, 220, 101);
  height: 100%;
  width: 100%;
  transform: skew(45deg, -45deg);
  transform-origin: 100% 0;
}
.cell-am-indisponible::before {
  background-color: rgb(255, 68, 34);
  height: 100%;
  width: 100%;
  transform: skew(-45deg, 45deg);
  transform-origin: 100% 0;
}
.cell-pm-indisponible::after {
  background-color: rgb(255, 68, 34);
  height: 100%;
  width: 100%;
  transform: skew(45deg, -45deg);
  transform-origin: 100% 0;
}

.light.cell-am-disponible::before {
  background-color: rgba(149, 220, 101, 0.4);
  height: 100%;
  width: 100%;
  transform: skew(-45deg, 45deg);
  transform-origin: 100% 0;
}
.light.cell-pm-disponible::after {
  background-color: rgba(149, 220, 101, 0.4);
  height: 100%;
  width: 100%;
  transform: skew(45deg, -45deg);
  transform-origin: 100% 0;
}
.light.cell-am-indisponible::before {
  background-color: rgb(255, 68, 34, 0.4);
  height: 100%;
  width: 100%;
  transform: skew(-45deg, 45deg);
  transform-origin: 100% 0;
}
.light.cell-pm-indisponible::after {
  background-color: rgb(255, 68, 34, 0.4);
  height: 100%;
  width: 100%;
  transform: skew(45deg, -45deg);
  transform-origin: 100% 0;
}
.cell-non-applicable::before {
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  transform: skew(-45deg, 45deg);
  transform-origin: 100% 0;
}
.cell-non-applicable::after {
  background-color: rgb(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  transform: skew(45deg, -45deg);
  transform-origin: 100% 0;
}

.cell-date-selected {
  content: "";
  position: relative;
  width: 25px;
  height: 3px;
  z-index: 9;
  background-color: #4d88ff;
}
.cell-date-selected-debut-fin {
  content: "";
  position: relative;
  width: 25px;
  height: 3px;
  z-index: 9;
  background-color: #0054ff;
}

.calendar .selectedCalendarDate {
  position: absolute;
  left: 0px;
  top: -6px;
}
