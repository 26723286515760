.confirmation-option-reservation-auto-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

.confirmation-option-reservation-auto-content {
    max-width: 30em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3em 1fr 3em;
    border: 2px solid var(--primary-color);
    background-color: white;
}

.confirmation-option-reservation-auto-header {
    grid-row: 1/1;
    background-color: var(--primary-color);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
}

.confirmation-option-reservation-auto {
    display: grid;
    grid-row: 2/2;
    padding: 0 10px;
    grid-template-columns: 2fr;
    justify-content: center;
}

.confirmation-option-reservation-auto p {
    text-align: center;
}

.confirmation-option-reservation-auto-content .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.confirmation-option-reservation-auto-content .buttons button {
    width: 100px;
    height: 25px;
    padding: 0;
    margin: 0;
}

.confirmation-option-reservation-auto-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 3px 0;
}

.confirmation-option-reservation-auto-button {
    grid-row: 3/3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
}

.confirmation-option-reservation-auto-button button {
    padding: 5px;
    width: 100px;
    margin: 5px;
}
